/* styles container-user */
.container-user-main{
   display: flex;
   flex-direction: column;
   margin: 0;
   right: 100px;
   top: 50%;
   transform: translateY(-50%);
   align-items: center;
   position: absolute;
}
/* styles container-user-data */
.container-user-icon-user{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 4px;
}
.container-user-icon-user .info-data{
    text-align: right;
}
.container-user-icon-user h4{
    font-family:  'Poppins';
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    margin-right: 7px;
}
.container-user-icon-user h5{
    font-family:  'Poppins';
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    margin-right: 7px;
}

@media screen and (max-width: 768px) {
    .container-capa{
        height: auto;
        top: 0;
    }

    .container-user-main,.container-header {
        display: none;
    }
}