.logo{
    width: 100%;
    margin-top: 80px;
    text-align: center;
}
.logo-div{
    width: 100%;
    margin-top: 80px;
    text-align: center;
}
.image-logo{
    height: 15vh;
    margin: 0 auto;
}
.container-main{ 
    width: 418px;
    height:  380px;

    background-color:  rgba(0, 0, 0, 0.5);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;

    margin-left: auto;
    margin-right: auto;
    margin-top:  4rem;
    padding: 1rem;

    align-items: center;
    justify-content: center;

}
/* styles conatiner main  title*/
.title{
    color:  #fff;
}
/* styles conatiner main  subtitle*/
.container-main .subtitle{
    margin-top: 5px;
    width: 24rem;
    color:  var(--primary-color);
    text-align:center;
    font-size: 26px;
}
.container-main .subtitle-error{
    margin-top: 1rem;
    padding: 4px;
    width: 24rem;
    color:  var(--primary-color);
    background-color: red;
    border-radius: 8px;
    text-align:center;
    font-size: 26px;
    /* margin-left: 10px; */
}
/* styles conatiner main  subtitle span*/
.container-main .subtitle span{
    color:  var(--primary-color);
    text-align:center;
    width: 18rem;
}
.button{
    margin-top: 2rem;
    /* margin-left:  5rem; */
}
.form{
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    /* margin-left: 2.5rem; */
    
}
.container-input .Input-forms::placeholder{
    color: #000;
    font-size: 22px;
}

.title-bemvindo{
    font-size: 32px;
    color: #FF5722;
    text-align: center;
}

@media screen and (max-width: 768px) {
    #root{
        display: inline-block;
        width: 100%;
    }   
    .logo-div {
        width: 80%;
        margin: 50px auto 0 auto;
        text-align: center;
    }
    .image-logo{
        width: 100%;
        height: auto;
    }
    .container-main .subtitle{
        width: 100%;
        text-align: center;
        font-size: 16px;
        margin: 15px 0 0 0;
    }
    .container-main{
        width: 80%;
        margin: 30px auto 0 auto;
    }
    .form{
        /* margin: 20px 0 0 0; */
    }
    .Input-forms{
        /* margin: 10px 0 0 0; */
        width: 100%;
        /* padding: 0 20px; */
        box-sizing: border-box;
    }
    .icon-message{
        display: none;
    }
    .icon-asteriks{
        display: none;
    }

    .title-bemvindo{
        font-size: 23px;
    }
    .button{
        margin: 30px 0 0 0;
        width: 100%;
        text-align: center;
    }
    .container-input{
        /* align-items: center; */
    }
}