/* styles containe-home */
.container-home{
    width: 100%;
    height: 1800px;
    background-color: var(--background-color);
}
/* styles container-capa */
.container-capa{
    position: relative;
}
/* styles photo-capa */
.photo-capa{
    width: 100%;
}
/* styles container-user-data */
.container-user-data{
    width: 100%;
    position: absolute;
    margin-top: 50rem;
    display: flex;
}
.container-search-filmes{
    margin-top: 30px;
    padding: 24px;
}
/* styles container-search-H1 */
.container-search-filmes h1{
    font-family: "Poppins";
    font-weight: 400;
    color: var(--button-color);
    margin-bottom: 0.4rem;

}
/* styles container-search-filme */
.search-filme{
    width: 100%;
    height: 3rem;

    border-radius: 6px;
    border: 2px solid  var(--border-solid);
    outline: none;

    padding-left: 10px;
    font-size: 0.85rem;

}
/* styles container-category */
.container-category{
    display: flex;
    align-items: center;
    padding: 24px;
    margin-top: -2rem
 ;
}
/* styles container-category-sub*/
.container-category-sub{
    border: 1px solid var(--border-solid);
    margin-right: 20px;
    padding: 4px;
}
/* styles container-subtitle */
.container-subtitle{
    font-family:  'Poppins';
    font-size: 1rem;
}
/* styles container-category-filme*/
.container-category-filme{
  
    flex-wrap: wrap;
}
/* styles firts-title-filme*/
.firts-title-filme{
    font-family: 'Nunito', sans-serif;
    color:  var(--orange);
    margin-left: 2rem;
    margin-bottom: 1.5rem;
    font-size: 2rem;
}
.container-content{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
/* styles main*/
.main{
    width: 230px;
    height: 313px;
   
    display: flex;
    align-items: center;
    border-radius: 20px;
    cursor: pointer;
}
/* styles container-listing-image*/
.container-listing-filmes{
    height: 313px;
   
    display: flex;
    margin-right: 20px;
    position: relative;
    border: none;
    cursor: pointer
 

}
.main-container{
    margin: 0 2%;
    width: 100%;
}
/* styles container-titlr-filmes*/
.container-title-filmes{
    position: absolute;

}
/* styles container-title-filmes*/
.title-filmes{
    position: absolute;
    margin-top: 15rem;
    width: 100%;
    background-color: var(--orange);
    height: 74px;
    text-align: center  ;
    border-radius: 13px;
    font-size: 26px;
    color: var(--primary-color);
  
}
/* styles container-title-firstshors*/
.title-firtshors{
    position: absolute;
    margin-top: 17.5rem;
    width: 16.3%;;
    height: 74px;
    margin-left: 45px;
    border-radius: 13px;
    color: var(--primary-color);
  
}
/* styles container-listing-image*/
.title-filmesss{
    position: absolute;
    margin-top: 17.5rem;
    width: 16.3%;;
    height: 74px;
    margin-left: 130px;
    border-radius: 13px;
    color: var(--primary-color);
  
}
/* styles container-title-line*/
.title-line{
    position: absolute;
    margin-top: 17rem;
    width: 16.3%;;
    height: 74px;
    margin-left: 105px;
    border-radius: 13px;
  
    font-family: "Poppins";
    font-size: 36px;
    font-weight: 200;
    color: var(--primary-color);
  
}
/* styles container-image-filme*/
.conatiner-image-filme{
}
/* styles container-image-filmes*/
.image-filmes{
    width: 230px;
    height: 313px;
    border-radius: 20px;
}
/* styles container-last-title-filme*/
.title-detalhes{
    font-size: 30px;
    font-family: 'Nunito', sans-serif;
    font-weight: 300;
    color: var(--orange);
}
/* styles container-input-main*/
.container-input-main{
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 1rem;
  
}
/* styles container-input*/
.container-input{
    display: flex;
    flex-direction: column;
}
.container-input ::placeholder{
    color: var(--color-black);
    font-size: 1rem;
    font-family:  'Poppins';
}
/* styles input-detalhe*/
.input-detalhe{
    width: 250px;
    height: 40px;
    border-radius: 4px;
    border: 2px solid var(--border-solid);
  
    margin: 8px;
    padding-left: 1rem;
}
/* styles title-input*/
.title-input{
    font-family: 'Nunito', sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: var(--border-solid-hover);
    margin-left: 0.6rem;

}
/* styles container-pai*/
.container-pai{
    margin: 0 auto;
    width: 1200px;
    max-width: 95%;
}
/* styles container-pai-filme*/
.container-pai-filme{
    margin: 50px auto;
    display: flex;
    
}
/* styles container-pai-filme .infomacoes-detalhes*/
.container-pai-filme .informacoes-detalhes{
    width: 100%;
    margin-left: 40px;
}
/* styles container-pai-filme .infomacoes-detalhes .detalhes-filme-direita*/
.container-pai-filme .informacoes-detalhes .detalhes-filme-direita{
   
}
/* styles container-pai-filme .infomacoes-detalhes .calendario-direita*/
.container-pai-filme .informacoes-detalhes .calendario-direita{
    margin: 40px 0 0 0;
}
/* styles detalhes-filme*/
.detalhes-filme{
    position: relative;
    width: 300px;
}
/* styles detalhes-filme img*/
.detalhes-filme img{
    width: 100%;
    max-height: 600px;
}
/* styles- detalhes .info-filme*/
.detalhes-filme .infos-filme-calendary{
    padding: 20px;
    position: absolute;
    bottom: 50px;
    padding-top: 80px;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 10% , rgba(255,255,255,0.75) 15%, rgba(255,255,255,0.9) 20%,rgba(255,255,255,1) 30%, rgba(255,255,255,1) 100% );
}
/* styles detalhes-filme .info-filme-h2*/
.detalhes-filme .infos-filme-calendary h2{
    margin: 0 0 15px 0;
}
/* styles detalhes-filme-info-filme-p*/
.detalhes-filme .infos-filme-calendary p{
    margin: 6px 0 0 0;
    color: #707070;
}
/* styles detalhes-filme .info-filme h6*/
.detalhes-filme .infos-filme-calendary h6{
    color: #AAA;
    margin: 20px 0 0 0;
}
/* styles calendario-direita*/
.calendario-direita-cabecalho{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between ;
    padding: 20px 10px;
}
/* styles title-calendary*/
.title-calendary{
    font-size: 30px;
    font-family: 'Nunito', sans-serif;
    font-weight: 300;
    color: var(--orange);
}
/* styles title-sinopse*/
.title-sinopse{
    font-size: 24px;
    font-family: 'Nunito', sans-serif;
    color: var(--border-solid-hover)
}
/* styles tabela-cine*/
.tabela-cine{
    width: 100%;
    border-collapse:collapse;
    margin-bottom: 40px;
}

/* styles detalhes tabela-cine thead tr*/
.tabela-cine thead tr{
    
    background: #eee;
    border: 1px solid #ccc;
}
/* styles detalhes tabela-cine thead tr th*/
.tabela-cine thead tr th{
    padding: 10px;
    text-align: start;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
}
/* styles detalhes tabela-cine tbody*/
.tabela-cine tbody tr{
}
/* styles detalhes tabela-cine tbody tr td*/
.tabela-cine tbody tr td{
    padding: 10px;
    text-align: start;
    font-family: 'Nunito', sans-serif;
}
