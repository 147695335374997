/* styles container-search-H1 */
.container-search-filmes h1{
    font-family: "Poppins";
    font-weight: 400;
    color: var(--button-color);
    margin-bottom: 0.4rem;

}
.container-collum{
    background-color: #33acff;
    width: 100%;
}
/* styles containe-home */
.container-home{
    width: 100%;
    height: auto;
    min-height: 500px;
    background-color: var(--background-color);
}
/* styles container-capa */
.container-capa{
    position: relative;
}
/* styles photo-capa */
.photo-capa{
    width: 100%;
}
.container-search-filmes{
    margin-top: 30px;
    padding: 24px;
}
/* styles container-search-filme */
.search-filme{
    width: 100%;
    height: 3rem;

    border-radius: 6px;
    border: 2px solid  var(--border-solid);
    outline: none;

    padding-left: 10px;
    font-size: 0.85rem;

}
/* styles container-main-pai*/
.container-main-pai{
    margin: 0 auto;
    width: 1200px;
    max-width: 95%;
}
/* styles title-informations*/
.title-informations{
    font-family: 'Nunito', sans-serif;
    font-size: 1.7rem;
    margin-top: 2rem;
    color: var(--color-description);
}
.container-area-informations{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
}
.container-header-infomations01{
    width: 550px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--border-solid);
    border-radius: 2px;
    padding: 10px;
}
.container-header-infomations01 h1{
    font-family: 'Nunito', sans-serif;
    font-size: 1.4rem;
    color: var(--color-description);
}
select{
    width: 100px;
    height: 30px;
    border: 1px solid var(--border-solid);
    border-radius: 6px;
}
.line-first{
    margin-top: 1rem;
    width: 60%;
    height:10px;
    border-radius: 3px;
    background-color: var(--color-dark-grreen);
    border:  1px solid var(--color-dark-grreen);;
}
.container-header-infomations02{
    width: 550px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    border: 1px solid var(--border-solid);
    border-radius: 2px;
    padding: 10px;
}
.container-header-infomations02 h1{
    font-family: 'Nunito', sans-serif;
    font-size: 1.4rem;
    color: var(--color-description);
}

.footer-inform{
    margin-top: 1rem;
    display: flex;
    justify-content: space-around;
}
.container-card{
    border: 1px solid var(--border-solid);
    width: 100px;
    height: 155px;
   
    
}
.header-footer-info1{
    background-color: #D6EAC5;
    width: 100px;
    height: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-bottom: 1px solid #cecece;
}
.header-footer-info1 h1{
    font-size: 0.8rem;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    color: var(--color-dark-grreen);
    width: 100%;

}

.header-footer-info2{
    background-color: #BBE8F2;
    width: 100px;
    height: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-bottom: 1px solid #cecece;
}
.header-footer-info2 h1{
    font-size: 0.8rem;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    text-align: center;
    color: #3686AF;
    width: 100%;

}
.header-footer-info3{
    background-color: #FBEED4;
    width: 90px;
    height: 20px;
    padding: 0px 0px 0px 10px;
    align-items: center;
    display: flex;
    border-bottom: 1px solid #cecece;
}
.header-footer-info3 h1{
    font-size: 0.9rem;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    color: #C1994D;
    text-align: center;

}
.header-footer-info4{
    background-color: #EFD3D7;
    width: 100px;
    height: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-bottom: 1px solid #cecece;
}
.header-footer-info4 h1{
    font-size: 0.8rem;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    text-align: center;
    color: #BB4945;
    width: 100%;

}

.header-footer-info5{
    background-color: #3E8ACC;
    width: 100px;
    height: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-bottom: 1px solid #cecece;
}
.header-footer-info5 h1{
    font-size: 0.8rem;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    text-align: center;
    color: #fff;
    width: 100%;
 

}
.description{
    margin-top: 3rem;
    text-align: center;
    font-size: 32px;
}
.container-relatorios{
    border: 1px solid var(--border-solid);
}
.container-collumn-icons{
    display: flex;
}

.container-icons-main{
    display: flex;
    flex-direction: column;
}
.container-info-relatorio{
    display: flex;
    width: 100%;
    padding: 24px;
    align-items: center;
}
.icon-andress{
    font-size: 30px;
    margin-right: 10px;
    color: green;
}
.icon-pencil{
    height: 25px;
    width: 25px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 10px;
    color:#fff;
    background-color: green;
    padding: 2px;
}
.icon-eayer{
    height: 25px;
    width: 25px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 10px;
    color:#fff;
    background-color: rgb(201, 198, 23);
    padding: 2px;
}
.container-description span{
    font-family: 'Nunito', sans-serif;
    font-size: 28px;
}
.container-description p{
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    color: var(--color-description)
}
.container-icons-main02{
    display: flex;
    flex-direction: column;
}
.container-info-relatorio02{
    display: flex;
    width: 100%;
    padding: 24px;
    align-items: center;
}
.icon-date02{
    height: 25px;
    width: 25px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 10px;
    color:#fff;
    background-color: #3933ff;
    padding: 2px;
}
.icon-circle02{
    height: 25px;
    width: 25px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 10px;
    color:#fff;
    background-color: #33acff;
    padding: 2px;
}
.icon-bandeira02{
    height: 25px;
    width: 25px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 10px;
    color:#fff;
    background-color: #c43522;
    padding: 2px;
}
.container-description02 span{
    font-family: 'Nunito', sans-serif;
    font-size: 28px;
}
.container-description02 p{
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    color: var(--color-description)
}

/* 
FORMATAÇÃO RELETORIOS VENDENDORES
*/
.container-vendedores-main{
    margin-top: 2rem;
    width: 100%;
    padding: 5px;
    border: 1px solid var(--border-solid);
}
.container-border-header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.header-vendedores{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
}
.header-vendedores h1{
    font-family: 'Nunito', sans-serif;
    font-size: 1.4rem;
    color: var(--color-description);
}
.button-cadastrar{
    width: 170px;
    height: 44px;

    background-color: var(--color-dark-grreen);
    border:  1px solid var(--button-color-calendary);
    border-radius: 4px;
    
    color: var(--primary-color);
    font-weight: bold;
    cursor: pointer;
    margin-top: -0.3rem;
    padding: 4px;;
}
.button-cadastrar-clientes span{
    color: var(--primary-color);
    font-family: 'Nunito', sans-serif;
    font-size: 1rem;
    font-weight: bold;

}
/* styles tabela-information*/
.tabela-information{
    width: 100%;
    border-collapse:collapse;
}
/* styles detalhes tabela-information thead tr*/
.tabela-information thead tr{
    background: #eee;
    border: 1px solid #ccc;
}
/* styles detalhes tabela-information thead tr th*/
.tabela-information thead tr th{
    padding: 10px;
    text-align: start;
    font-family: 'Nunito', sans-serif;
    font-size: 13px;
}
/* styles detalhes tabela-information tbody*/
.tabela-information tbody tr:hover{
    background-color: #eee;
}
/* styles detalhes tabela-information tbody tr td*/
.tabela-information tbody tr td{
    padding: 10px;
    text-align: start;
    font-family: 'Nunito', sans-serif;
    font-size: 13px;

}
.container-ver{
    margin-top: 5px;
    width: 70px;
    height:  20px;
    background-color: #103FAC;
    border-radius: 6px;
    position: absolute;
    cursor: pointer;
}
.title-container-ver{
    color: #FFF;
    margin-left: 16px;
}

.input-group-ingressos{
    display: flex;
    margin-top: 20px;
}

.input-group-ingressos input{
    padding: 10px;
    width: 320px;
    height: 40px;
    box-sizing: border-box;
    border: 1px solid #ccc;
}

.input-group-ingressos select{
    padding: 10px;
    width: 210px;
    height: 40px;
    box-sizing: border-box;
    margin:  0 0 0 10px;
    border: 1px solid #ccc;
}

.input-group-ingressos .btn-editar{
    border: none;
    text-decoration: none;
    padding: 7px 20px;
    margin-left: 10px;
    background: var(--color-dark-grreen);
    color: #fff;
}

.relatorio-vendas-dia{

    border: 1px solid #CECECE;
    margin-top: 20px;
    padding: 20px;
}

.btn-ver{
    padding: 3px 10px;
    cursor: pointer;
    border: none;
    background: #33acff;
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
}

.titulos-box{
    display: flex;
}

.titulos-box .titulo-box{
    width: 50%;
    text-align: center;
}

.titulos-box .titulo-box h2{
    margin: 0 0 20px 0;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--color-description);
}

.divisor{
    width: 1px;
    height: 100px;
    background-color: #cecece;
}

.tickets-boxes{
    display: flex;
    justify-content: space-between;
}
.tickets-boxes.center{
    margin: 40px 0;
    justify-content: center;
}
.tickets-boxes.center .ticket-box{
    margin:  0 20px;
}
.tickets-boxes .ticket-box{
    width: 20%;
    display: flex;
    justify-content: space-between;
    padding: 15px;
}
.tickets-boxes .ticket-box.green{
    background-color: #3F8E15;
}
.tickets-boxes .ticket-box.blue{
    background-color: #3DB0FC;
}
.tickets-boxes .ticket-box.blue-rounded{
    background-color: #006699;
    border-radius: 10px;
}
.tickets-boxes .ticket-box .infos-left{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.tickets-boxes .ticket-box .infos-left h2{
    color: #ffffff;
}
.tickets-boxes .ticket-box .infos-left p{
    color: #ffffff;
}
.tickets-boxes .ticket-box .icon-box{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.tickets-boxes .ticket-box .icon-box img{
    width: 75%;
}
.tickets-boxes .ticket-box .icon-box h2{
    color: #ffffff;
    font-size: 20px;
}

.flex-consultas{
    display: flex;
    justify-content: space-between;
}

.flex-consultas .item-flex{
    flex: 1;
    margin: 0 20px;
}

.relatorio-vendas-dia table{
    border-collapse: collapse;
    width: 100%;
}

.gKFELF {
    font-size: 13px !important;
    line-height: 24px !important;
}

.relatorio-vendas-dia table tr td{

}
.relatorio-vendas-dia table tr th{
    text-align: left;
}
.relatorio-vendas-dia table tr td,.relatorio-vendas-dia table tr th{
    border: 1px solid #000;
    padding: 5px 10px;
}

.relatorio-ventas-cierre table{
    border-collapse: collapse;
    width: 100%;
}

.relatorio-ventas-cierre table tr td{

}
.relatorio-ventas-cierre table tr th{
    text-align: left;
}
.relatorio-ventas-cierre table tr td,.relatorio-ventas-cierre table tr th{
    border: 1px solid #000;
    padding: 3px 3px;
}

.abas{
    margin: 40px 0;
    border-bottom: 1px solid #ccc;
}

.abas .aba{
    background-color: #ffffff;
    border: none;
    padding: 7px 12px;
    font-size: 13px;
    margin-right: 10px;
    cursor: pointer;
}

.abas .aba:hover{
    background-color: #eeeeee;
}

.abas .aba.active{
    background-color: #F26522;
    color: #ffffff;
}

.filtros{
    display: flex;
    justify-content: center;
}
.filtros .filtro-field{
    padding: 20px;
    display: flex;
    align-items: center;
}
.filtros .filtro-field p{
    margin-right: 10px;
}
.filtros .filtro-field input{
    width: 150px;
    padding: 5px 12px;
    border: 1px solid #cccccc;
    border-radius: 5px;
}
.filtros .filtro-field select{
    min-width: 150px;
    padding: 5px 12px;
    border: 1px solid #cccccc;
    border-radius: 5px;
}
.filtros .btn-generar{
    background-color: #006699;
    padding: 7px 15px;
    color: #fff;
    border: none;
    border-radius: 4px;
}

.filtros .btn-generar-pdf{
    background-color: #c43522;
    padding: 7px 15px;
    color: #fff;
    border: none;
    border-radius: 4px;
    margin-left: 7px;
}

.btn-generar-excel{
    background-color: #34eb67;
    padding: 7px 15px;
    color: #fff;
    border: none;
    border-radius: 4px;
    margin-left: 7px;
}

.box-tbl-vendas{
    width: 100%;
    margin: 40px 0 60px 0;
    display: inline-block;
}

.tbl-vendas{
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 15px;
}

.tbl-vendas td,.tbl-vendas th{
    border: 1px solid #999;
    padding: 3px 3px;
    font-size: 0.8em;
}

.box-tables{
    display: flex;
    margin: 0 0 30px 0;
}

.box-tables.table-center{
    justify-content: center;
}

.box-table{
    width: 33.3%;
    padding: 20px;
}

.box-table.table-60{
    width: 60%;
    padding: 20px;
}

.box-table table{
    border-collapse: collapse;
    width: 100%;
}

.box-table tr th,.box-table tr td{
    border: 1px solid #000;
    padding: 5px 12px;
}

.pagination{
    display: flex;
    justify-content: flex-start;
    margin: 20px 0;
}

.pagination .page-item{
    padding: 5px 10px;
    background-color: #efefef;
    margin-right: 4px;
    color: #666666;
    border-radius: 3px;
    cursor: pointer;
    text-decoration: none;
    display: block;
    border: none;
    outline: none;
}

.pagination .page-item:hover{
    text-decoration: none;
    background-color: #f4f4f4;
}
.pagination .page-item.active:hover,.pagination .page-item.active{

    background-color: #F26522;
    color: #ffffff;
}