/* container button */
.container-input-button-ingressar{
 
}
.button-ingressar-pelicula{
    width: 170px;
    height: 44px;

    background-color: var(--color-dark-grreen);
    border:  1px solid var(--button-color-calendary);
    border-radius: 4px;
    
    color: var(--primary-color);
    font-weight: bold;
    cursor: pointer;
    margin-top: -0.3rem;
    padding: 4px;;
   
}