/* styles containe-home */
.container-home {
    width: 100%;
    height: auto;
    min-height: 500px;
    background-color: var(--background-color);
}

/* styles container-capa */
.container-capa {
    position: relative;
}

/* styles photo-capa */
.photo-capa {
    width: 100%;
}

.container-search-filmes {
    margin-top: 30px;
    padding: 24px;
}

/* styles container-search-H1 */
.container-search-filmes h1 {
    font-family: "Poppins";
    font-weight: 400;
    color: var(--button-color);
    margin-bottom: 0.4rem;

}

/* styles container-search-filme */
.search-filme {
    width: 100%;
    height: 3rem;

    border-radius: 6px;
    border: 2px solid var(--border-solid);
    outline: none;

    padding-left: 10px;
    font-size: 0.85rem;

}

/* styles container-pai*/
.container-pai {
    margin: 0 auto;
    width: 1200px;
    max-width: 95%;
}

/* styles container-pai-filme*/
.container-pai-filme {
    margin: 50px auto;
    display: flex;

}

.container-main-cupon {
    margin: 0 auto;
    width: 1200px;
    max-width: 95%;
    /* border: 1px solid red; */
}

.container-button-cupon {
    width: 300px;
    margin: 0px 81%;
    text-align: right;
    margin-top: 2rem;
}

.button-cadastrar-cupon {
    width: 180px;
    background-color: var(--color-dark-grreen);
    border: 1px solid var(--color-dark-grreen);
    height: 40px;
    border-radius: 6px;
    padding: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-cadastrar-cupon span {
    color: var(--primary-color);
    font-family: 'Nunito', sans-serif;
    font-size: 1rem;
    font-weight: 600;

}

.title-cupon {
    font-family: "Poppins";
    font-weight: 400;
    color: var(--color-description);
    margin-bottom: 1rem;
    font-size: 1.5rem;
}

.input-search-cupon {
    width: 95%;
    height: 40px;
    border: 1px solid var(--border-solid);
    border-radius: 6px;
    padding-left: 20px;
    outline: none;
}

::placeholder {
    color: var(--color-description);
    font-size: 18px;
}

.container-table-cupon {
    margin-top: 2rem;
}

/* styles tabela-cine-cliente*/
.tabela-cine-cupon {
    width: 97%;
    border-collapse: collapse;
}

/* styles detalhes tabela-cine-cliente thead tr*/
.tabela-cine-cupon thead tr {

    background: #eee;
    border: 1px solid #ccc;
}

.content-vip {
    background-color: var(--color-dark-grreen);
    padding: 6px;
    color: var(--primary-color);
    font-family: 'Nunito', sans-serif;
}

.content-billete {
    background-color: var(--color-dark-grreen);
    height: 20px;
    padding: 6px;
    color: var(--primary-color);
    font-family: 'Nunito', sans-serif;
    font-size: 18px;
    margin-left: 16%;
    cursor: pointer;
    border-radius: 6px;


    ;

}

/* styles detalhes tabela-cine-cliente thead tr th*/
.tabela-cine-cupon thead tr th {
    padding: 10px;
    text-align: start;
    font-weight: bold;
    font-family: 'Nunito', sans-serif;
    font-size: 18px;
}

/* styles detalhes tabela-cine-cliente tbody*/
.tabela-cine-cupon tbody tr {}

/* styles detalhes tabela-cine-cliente tbody tr td*/
.tabela-cine-cupon tbody tr td {
    padding: 10px;
    text-align: start;
    font-family: 'Nunito', sans-serif;
    font-size: 18px;
}

.filtros {
    display: flex;
    justify-content: center;
}

.filtros .filtro-field {
    padding: 20px;
    display: flex;
    align-items: center;
}

.filtros .filtro-field p {
    margin-right: 10px;
}

.filtros .filtro-field input {
    width: 150px;
    padding: 5px 12px;
    border: 1px solid #cccccc;
    border-radius: 5px;
}

.filtros .filtro-field select {
    min-width: 150px;
    padding: 5px 12px;
    border: 1px solid #cccccc;
    border-radius: 5px;
}

.filtros .btn-generar {
    background-color: #006699;
    padding: 7px 15px;
    color: #fff;
    border: none;
    border-radius: 4px;
}

.filtros .btn-generar-pdf {
    background-color: #c43522;
    padding: 7px 15px;
    color: #fff;
    border: none;
    border-radius: 4px;
    margin-left: 7px;
}