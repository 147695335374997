/* styles containe-home */
.qr-area-read{
    width: 80%;
    max-width: 500px;
    margin: 40px auto;
}

.qr-area-read p{
    width: 100%;
    padding: 20px 0;
    font-size: 25px;
    background-color: #fff;
    color: #999;
}

.buttons-autenticar{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 100%;
}

.btn-autenticar{
    padding: 10px 20px;
    font-size: 21px;
    background-color: #1984db;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    position: absolute;
    bottom: 50px;
}

.conainer-selecione-date,.container-main-buuton{
    width: 100%;
    padding: 0 0;
}

.message-autenticar{
    display: flex;
    height: 300px;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
}

.message-autenticar.success,.message-autenticar.error{
    position: fixed;
    top: 0;
    background-color: #4caf50;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    justify-content: center;
}

.message-autenticar.error{
    background-color:#d94e4e;
}

.message-autenticar.success .btn-autenticar,.message-autenticar.error .btn-autenticar{
    background-color: #1984db;
    box-shadow: 0 0 8px rgba(0,0,0,0.01);
}

.message-autenticar.success h1,.message-autenticar.error h1{
    color: #ffffff;
}

.message-autenticar img{
    width: 50%;
    height: auto;
}

.message-autenticar h1{
    margin-top: 30px;
    width: 100%;
    text-align: center;
    color: #999999;
    margin-bottom: 20px;
}

.tbl-ingresso{
    max-width: 70%;
    width: 70%;
    margin: 0 auto;
    border-collapse: collapse;
}

.tbl-ingresso tr th{
    width: 60px;
}

.message-autenticar.success .tbl-ingresso tr th,.message-autenticar.success .tbl-ingresso tr td{
    border: 3px solid #4caf50;
    background-color: #6dbf71;
    text-align: left;
    color: #ffffff;
    font-family: Arial;
    font-size: 13px;
    padding: 7px;
}
.message-autenticar.error .tbl-ingresso tr th,.message-autenticar.error .tbl-ingresso tr td{
    border: 3px solid #d94e4e;
    background-color: #d55e5e;
    text-align: left;
    color: #ffffff;
    font-family: Arial;
    font-size: 13px;
    padding: 7px;
}
.message-autenticar.success .tbl-ingresso tr th{
    text-align: right;
}
.message-autenticar.error .tbl-ingresso tr th{
    text-align: right;
}

@media screen and (max-width: 768px) {
    .title-informations{
        width: 100%;
        text-align: center;
    }
    .container-home{
        height: 100vh !important;
    }

    .main-container{
        margin: 0;
    }

    .container-DATAS{
        max-width: 90%;
        display: inline-block;
        white-space: nowrap;
        overflow-x: auto;
    }
}

.qr-image-wrapper section { 
    border-radius: 16px;                   
}

.qr-image-wrapper section div {
    box-shadow: rgba(244, 117, 31, 0.5) 0px 0px 0px 5px inset !important;
}