.content-selecione-date-Dom{
    width: 70px;
    height:70px;
    border: none;
    padding: 10px 10px;
    margin: 10px;
    cursor: pointer;

}
.content-selecione-date-date-Dom{
    width: 70px;
    height:70px;
    border: none;
    padding: 2px 10px;
    margin: 0 10px 0 0;
    cursor: pointer;
    background-color: var(--border-solid);
    border-radius: 4px;
}

.content-selecione-date-date-Dom p{
    margin: 0;
}
.content-selecione-date-date-Dom-active{
    width: 70px;
    height:70px;
    border: none;
    padding: 2px 10px;
    margin: 0 10px 0 0;
    cursor: pointer;
    background-color: var(--color-dark-grreen);
    border-radius: 4px;
}
.content-selecione-date-date-Dom-active p{
    margin: 0;
}

.main-content-date-date-Dom{
   display: flex;
   flex-direction: column;
   align-items: center;
}

.main-content-date-date-Dom-active{
    color: var(--primary-color);
    font-family:  'Poppins';
    font-size: 17px;
}
.main-content-date-date-Dom .first-p{
    color: var(--color-description);
    font-family:  'Poppins';
    font-size: 0.8rem;
}
.main-content-date-date-Dom .first-p-active{
    color: var(--primary-color);
    font-weight: bold;
    font-family:  'Poppins';
    font-size: 0.8rem;
}

.main-content-date-date-Dom  .last-p{
    color: var(--color-black);
    font-family:  'Poppins';
    font-size: 17px;
    
}
.main-content-date-date-Dom  .last-p-active{
    color: #FFF;
    font-family:  'Poppins';
    font-size: 17px;
    font-weight: bold;
    
}
.main-content-date-date-Dom  .tri-p{
    color: var(--color-description);
    font-family:  'Poppins';
    font-size: 0.8rem;
    
}
.main-content-date-date-Dom  .tri-p-active{
    color: var(--primary-color);
    font-family:  'Poppins';
    font-size: 0.8rem;
    font-weight: bold;
    
}