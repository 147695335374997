/* container button */
.container-button{
    width: 140px;
    height: 40px;

    background-color: var(--button-color-calendary);
    border:  1px solid var(--button-color-calendary);
    border-radius: 4px;
    
    color: var(--primary-color);
    cursor: pointer;
    margin-top: -0.3rem;
}