/* styles continaer-navigation */
.container-header{
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color:  var(--background-color-header);
    opacity: 1;

    border-bottom: 1px solid var(--border-solid);
    border-width: 0%;

    margin-top:  -10px;
}
/* styles continaer-navigation */
.container-navigation{
    width: 100%;
    height: 24px;
    padding: 0;

    
    /* background-color: blue; */
}
/* styles continaer-ul */
.contianer-ul{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: left;
}
.contianer-ul-client{
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: left;
}
.container-client{
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.contianer-ul .container-navegaçao-client{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-left: -44rem;
}
/* styles continaer-li */
.contianer-li{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
   
}
.contianer-li.active a{
    color: #F26522;
}
/* styles continaer-li a:hover */
.contianer-li a{
    font-size: 1.4em;
    color: var(--border-solid);

    margin-top: 0;
    cursor: pointer;
}
/* styles continaer-li a:hover */
.contianer-li a:hover{
    font-size: 1.4em;
    color: var(--border-solid-hover);
}
.contianer-li.active a:hover{
    color: #F26522;
}
/* styles continaer-li a:active */
.contianer-li a:active{
    color: #F26522;
}

@media screen and (max-width: 768px) {
    .container-navigation{
        display: none !important;
    }
}