/* styles containe-home */
.container-home{
    width: 100%;
    height: auto;
    min-height: 500px;
    background-color: var(--background-color);
}
/* styles container-capa */
.container-capa{
    position: relative;
}
/* styles photo-capa */
.photo-capa{
    width: 100%;
}
.container-search-filmes{
    margin-top: 30px;
    padding: 24px;
}
/* styles container-search-H1 */
.container-search-filmes h1{
    font-family: "Poppins";
    font-weight: 400;
    color: var(--button-color);
    margin-bottom: 0.4rem;

}
/* styles container-search-filme */
.search-filme{
    width: 100%;
    height: 3rem;

    border-radius: 6px;
    border: 2px solid  var(--border-solid);
    outline: none;

    padding-left: 10px;
    font-size: 0.85rem;

}
/* styles container-pai*/
.container-pai{
    margin: 0 auto;
    width: 1200px;
    max-width: 95%;
}
/* styles container-pai-filme*/
.container-pai-filme{
    margin: 50px auto;
    display: flex;
    
}
/* styles container-pai-filme .infomacoes-detalhes*/
.container-pai-filme .informacoes-detalhes{
    width: 100%;
    margin-left: 40px;
}
/* styles container-pai-filme .infomacoes-detalhes .detalhes-filme-direita*/
.container-pai-filme .informacoes-detalhes .detalhes-filme-direita{
    
}
.container-main-sesion{
    display: flex;
}
.container-sesion{
    display: flex;
    align-items: center;
}
.main-bolinha1{
    width: 60px;
    height: 60px;
    border: 1px solid var(--orange);
    background-color: var(--orange);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.main-bolinha1 span{
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    color: var(--primary-color)
}
.bolinha1{
    width: 60px;
    height: 60px;
    border: 1px solid var(--orange);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bolinha1 span{
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    color: var(--color-text-bolinha)
}
.container-line{
    width: 90px;
    border: 1px solid var(--orange);
}
.description-bolinha{
    width: 82%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 6px;
}
.detalhes-filme-direita > .container-main-sesion{
    position: relative; 
    left: 50;
}
.description-bolinha p{
    font-family: 'Nunito', sans-serif;
    width: 140px;
    text-align: center;
    position: relative;
    left: -50px;
    color: var(--color-description);
}
/* styles container-pai-filme .infomacoes-detalhes .calendario-direita*/
.container-pai-filme .informacoes-detalhes .calendario-direita{
    margin: 40px 0 0 0;
}
/* styles detalhes-filme*/
.detalhes-filme{
    position: relative;
    width: 300px;
    height: 520px;
}
/* styles detalhes-filme img*/
.detalhes-filme img{
    width: 100%;
    max-height: 600px;
}
/* styles- detalhes .info-filme*/
.detalhes-filme .infos-filme-finally{
    padding: 20px;
    position: absolute;
    bottom: -150px;
    padding-top: 80px;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 10% , rgba(255,255,255,0.75) 15%, rgba(255,255,255,0.9) 20%,rgba(255,255,255,1) 30%, rgba(255,255,255,1) 100% );
}
/* styles detalhes-filme .info-filme-h2*/
.detalhes-filme .infos-filme-finally h2{
    margin: 0 0 15px 0;
}
/* styles detalhes-filme-info-filme-p*/
.detalhes-filme .infos-filme-finally p{
    margin: 6px 0 0 0;
    color: #707070;
}
/* styles detalhes-filme .info-filme h6*/
.detalhes-filme .infos-filme-finally h6{
    color: #AAA;
    margin: 20px 0 0 0;
}

.container-finaly-main{
    width:74%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center; 
}
.container-finaly{
    width:100px;
    height: 100px;
    background-color:  var(--color-dark-grreen);
    border-radius: 100%;
    margin: 6rem 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center; 
}
.container-title-finnaly{
    width:74%;
}
.title-finnaly{
    text-align:center;
    font-family:  'Poppins';
    font-size: 24px;
    font-weight: 400;
    margin-top: 1rem;
}

.tickets-list{
    text-align: center;
    margin-top: 20px;
}

.tickets-button{
    width: 100%;
    margin-top: 70px;
    text-align: center;
}

.tickets-button a{
    background-color: #00C455;
    padding: 10px 15px;
    border-radius: 7px;
    color: #ffffff;
    text-decoration: none;
    border: none;
    font-size: 18px;
}

.tickets-list a,.tickets-list button{
    background-color: #0099ff;
    border: none;
    margin: 20px 0 0 0;
    color: #ffffff;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
}