.container-area-modal-historico{
    background-color: #fff;
    width: 50%;
	padding: 20px;
	position: fixed;
    border-radius: 8px;
    z-index: 20;
    border: 1px solid black;
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
	margin: 120px 0 0 -0;
}
.button-closed-modal-historico{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid black;
    background-color: var(--primary-color);
    color: var(--color-black);
    font-weight: bold;
    cursor: pointer;
}
.title--modal-historico {
    text-align: center;
    font-family: 'Nunito', sans-serif;
    margin-bottom: 15px;
    color: black;
}
.body-modal-historico01{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}