.container-area-modal-Ingressar-pelicula{
    background-color: var(--color-dark-grreen);
    width: 400px;
	padding: 20px;

	position: fixed;
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
	margin: 40px 0 0 0;
    border-radius: 8px;
    z-index: 20;

}
.button-closed-modal-Ingressar-pelicula{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    color: var(--color-black);
    font-weight: bold;
    cursor: pointer;
}
.title--modal-Ingressar-pelicula {
    text-align: center;
    font-family: 'Nunito', sans-serif;
    margin-bottom: 15px;

}
.body-modal-Ingressar-pelicula01{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.container-input-ingressar-pelicula{
}
.container-input-ingressar-pelicula01 input{
    width: 90%;
    height: 25px;
    border: 1px solid var(--border-solid);
    border-radius: 4px;
    padding-left: 10px;
    margin-bottom: 10px;
    outline: none;
}
.container-input-ingressar-pelicula01 p{
    text-align: center;
    font-family: 'Nunito', sans-serif;
    font-size: 1rem;
    color: var(--primary-color);
    font-weight: bold;
    
}
.footer-button-add-pelicula{
    width: 180px;
    height: 25px;
    border: 1px solid var(--border-solid);
    background-color: var(--primary-color);
    border-radius: 4px;
    outline: none;
    margin-top: 1rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 30px;
    margin-left: 100px;
}
.footer-pelicula span{
    color:  var(--color-dark-grreen);
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    font-size: 1.1rem;
}
.btn-excluir{
    background-color: tomato;
    color: #fff;
    border: none;
    padding: 5px 12px;
    cursor: pointer;
    border-radius: 6px;
}
.btn-cancelar-excluir{
    background-color: #ddd;
    margin-left: 5px;
    color: #222;
    border: none;
    padding: 5px 12px;
    cursor: pointer;
    border-radius: 6px;
}
.text-center{
    text-align: center !important;
}
.container-input-ingressar-pelicula01 textarea {
    width: 90%;
    height: 50px;
    margin-left: 20px;
    border: 1px solid var(--border-solid);
}
.slc-chil{
    width: 100%;
}
