/* styles containe-home */
.container-home{
    width: 100%;
    height: auto;
    min-height: 650px;
    background-color: var(--background-color);
}
/* styles container-capa */
.container-capa{
    position: relative;
}
/* styles photo-capa */
.photo-capa{
    width: 100%;
}
.container-search-filmes{
    margin-top: 30px;
    padding: 24px;
}
/* styles container-search-H1 */
.container-search-filmes h1{
    font-family: "Poppins";
    font-weight: 400;
    color: var(--button-color);
    margin-bottom: 0.4rem;

}
/* styles container-search-filme */
.search-filme{
    width: 100%;
    height: 3rem;

    border-radius: 6px;
    border: 2px solid  var(--border-solid);
    outline: none;

    padding-left: 10px;
    font-size: 0.85rem;

}
/* styles container-pai*/
.container-pai{
    margin: 0 auto;
    width: 1200px;
    max-width: 95%;
}
/* styles container-pai-filme*/
.container-pai-filme{
    margin: 50px auto;
    display: flex;
    
}
/* styles container-pai-filme .infomacoes-detalhes*/
.container-pai-filme .informacoes-detalhes{
    width: 100%;
    margin-left: 40px;
}
/* styles container-pai-filme .infomacoes-detalhes .detalhes-filme-direita*/
.detalhes-filme-direita > .container-main-sesion{
    position: relative; 
    left: 50;
}
.container-main-sesion{
    display: flex;
}
.container-sesion{
    display: flex;
    align-items: center;
}
.main-bolinha1{
    width: 60px;
    height: 60px;
    border: 1px solid var(--orange);
    background-color: var(--orange);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.main-bolinha1 span{
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    color: var(--primary-color)
}
.bolinha1{
    width: 60px;
    height: 60px;
    border: 1px solid var(--orange);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bolinha1 span{
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    color: var(--color-text-bolinha)
}
.container-line{
    width: 90px;
    border: 1px solid var(--orange);
}
.description-bolinha{
    width: 82%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 6px;
}
.description-bolinha p{
    font-family: 'Nunito', sans-serif;
    width: 140px;
    position: relative;
    left: -50px;
    text-align: center;
    color: var(--color-description);
}
/* styles container-pai-filme .infomacoes-detalhes .calendario-direita*/
.container-pai-filme .informacoes-detalhes .calendario-direita{
    margin: 40px 0 0 0;
}
/* styles detalhes-filme*/
.detalhes-filme{
    position: relative;
    width: 300px;
    max-height: 620px;
}
/* styles detalhes-filme img*/
.detalhes-filme img{
    width: 100%;
    max-height: 600px;
}
/* styles- detalhes .info-filme*/
.detalhes-filme .infos-filme-billete{
    padding: 20px;
    position: absolute;
    bottom: -10px;
    padding-top: 80px;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 10% , rgba(255,255,255,0.75) 15%, rgba(255,255,255,0.9) 20%,rgba(255,255,255,1) 30%, rgba(255,255,255,1) 100% );
}
/* styles detalhes-filme .info-filme-h2*/
.detalhes-filme .infos-filme-billete h2{
    margin: 0 0 15px 0;
}
/* styles detalhes-filme-info-filme-p*/
.detalhes-filme .infos-filme-billete p{
    margin: 6px 0 0 0;
    color: #707070;
}
/* styles detalhes-filme .info-filme h6*/
.detalhes-filme .infos-filme-billete h6{
    color: #AAA;
    margin: 20px 0 0 0;
}
.content-payment{
    width: 100%
}
.title-billete{
    margin-bottom: 5px;
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 22px;
    color: var(--color-description);
    margin-top: 2rem;
    font-weight: bold;
}
.subtitle-header{
    text-align: center;
    margin-bottom: 5px;
    font-family: 'Nunito', sans-serif;
    color: var(--color-description);
    margin-top: 1rem;
}
.description-first{
    margin-top: 1rem;
}
.subtitle-first-text{
    font-family: 'Nunito', sans-serif;
    color: var(--color-description);
}
.subtitle-first-text-billete{
    font-family: 'Nunito', sans-serif;
    color: var(--color-black);
}


.footer-button-billete{
    text-align: right;
    margin-top: 2rem;
    margin-right: 150px;
}

.assentos-items{
    display: flex;
}

.assento-item{
    display: flex;
    margin: 5px 8px 0 0;
    border-radius: 4px;
    text-transform: uppercase;
    color: #ffffff;
    padding: 5px 10px;
    background-color: #69BF55;
}
