/* styles containe-home */
.container-home{
    width: 100%;
    height: 1800px;
    background-color: var(--background-color);
}
/* styles container-capa */
.container-capa{
    position: relative;
}
/* styles photo-capa */
.photo-capa{
    width: 100%;
}
.container-search-filmes{
    margin-top: 30px;
    padding: 24px;
}
/* styles container-search-H1 */
.container-search-filmes h1{
    font-family: "Poppins";
    font-weight: 400;
    color: var(--button-color);
    margin-bottom: 0.4rem;

}
/* styles container-search-filme */
.search-filme{
    width: 100%;
    height: 3rem;

    border-radius: 6px;
    border: 2px solid  var(--border-solid);
    outline: none;

    padding-left: 10px;
    font-size: 0.85rem;

}
/* styles container-pai*/
.container-pai{
    margin: 0 auto;
    width: 1200px;
    max-width: 95%;
}
/* styles container-pai-filme*/
.container-pai-filme{
    margin: 50px auto;
    display: flex;
    
}
/* styles container-pai-filme .infomacoes-detalhes*/
.container-pai-filme .informacoes-detalhes{
    width: 100%;
    margin-left: 40px;
}
/* styles container-pai-filme .infomacoes-detalhes .detalhes-filme-direita*/
.container-pai-filme .informacoes-detalhes .detalhes-filme-direita{
   
}
.container-main-sesion{
    display: flex;
}
.container-sesion{
    display: flex;
    align-items: center;
}
.main-bolinha1{
    width: 60px;
    height: 60px;
    border: 1px solid var(--orange);
    background-color: var(--orange);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.main-bolinha1 span{
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    color: var(--primary-color)
}
.bolinha1{
    width: 60px;
    height: 60px;
    border: 1px solid var(--orange);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bolinha1 span{
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    color: var(--color-text-bolinha)
}
.container-line{
    width: 90px;
    border: 1px solid var(--orange);
}
.description-bolinha{
    width: 82%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 6px;
}
.description-bolinha p{
    font-family: 'Nunito', sans-serif;
    width: 140px;
    text-align: center;
    position: relative;
    left: -50px;
    color: var(--color-description);
}
/* styles container-pai-filme .infomacoes-detalhes .calendario-direita*/
.container-pai-filme .informacoes-detalhes .calendario-direita{
    margin: 40px 0 0 0;
}
/* styles detalhes-filme*/
.detalhes-filme{
    position: relative;
    width: 300px;
    max-height: 620px;
}
/* styles detalhes-filme img*/
.detalhes-filme img{
    width: 100%;
    max-height: 600px;
}
/* styles- detalhes .info-filme*/
.detalhes-filme .infos-filme-carteira{
    padding: 20px;
    position: absolute;
    bottom: 50px;
    padding-top: 80px;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 10% , rgba(255,255,255,0.75) 15%, rgba(255,255,255,0.9) 20%,rgba(255,255,255,1) 30%, rgba(255,255,255,1) 100% );
}
/* styles detalhes-filme .info-filme-h2*/
.detalhes-filme .infos-filme-carteira h2{
    margin: 0 0 15px 0;
}
/* styles detalhes-filme-info-filme-p*/
.detalhes-filme .infos-filme-carteira p{
    margin: 6px 0 0 0;
    color: #707070;
}
/* styles detalhes-filme .info-filme h6*/
.detalhes-filme .infos-filme-carteira h6{
    color: #AAA;
    margin: 20px 0 0 0;
}
/* styles calendario-direita*/
.calendario-direita-cabecalho{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between ;
    padding: 20px 10px;
}
/* styles title-calendary*/
.title-calendary{
    font-size: 30px;
    font-family: 'Nunito', sans-serif;
    font-weight: 300;
    color: var(--orange);
    border-bottom: 1px solid var(--color-text-bolinha);
}
/* styles title-sinopse*/
.title-sinopse{
    font-size: 24px;
    font-family: 'Nunito', sans-serif;
    color: var(--border-solid-hover)
}
.conainer-selecione-date{

}

.conainer-selecione-date h6{
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 1rem;
    color: #666666;
}
.container-DATAS{
    
}
.container-main-buuton{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 0 0 0;
    max-width: 500px;
}
.btn-container{
    text-align: center;
}
.container-seccion-carteira{
    width: 100%;
    padding: 0 0;
    margin: 25px 0 0 0;
}
.container-seccion-carteira h6{
    font-size: 15px;
    font-family:  'Poppins';
    font-weight: 400;
}
.container-seccion-carteira > p, .title-cantidad{
    font-weight: bold;
    font-size: 16px;
    color: #666666;
    margin: 20px 0 1rem 0;
}
.container-secction-button{
    width: 120px;
    height: 84px;
    padding: 4px;
    border: 2px solid var(--color-text-bolinha);
    border-radius: 9px;
    background-color: var(--primary-color);
    margin-top: 1rem;
    cursor: pointer;
    margin: 0 10px 0 0;
}
.container-secction-button p{
    margin: 0;
    font-size: 12px;
}
.container-secction-button-selected{
    width: 120px;
    height: 80px;
    padding: 2px;
    border: 2px solid var(--color-dark-grreen);
    border-radius: 9px;
    background-color: var(--color-dark-grreen);
    margin-top: 1rem;
    cursor: pointer;
    margin: 0 10px 0 0;
    color: red;
}
.container-secction-button-selected p{
    margin: 0;
    font-size: 12px;
}
.button-first-txt-selected{
    font-family: 'Nunito', sans-serif;
    color: var(--primary-color);
    font-weight: 600;
}
.button-last-txt-selected{
    font-family: 'Nunito', sans-serif;
    color: var(--primary-color);
}
.button-tri-txt-selected{
    font-family: 'Nunito', sans-serif;
    color: var(--primary-color);
    font-weight: 600;
}
.button-first-txt{
    font-family: 'Nunito', sans-serif;
    color: var(--orange);
    font-weight: 700;
}
.button-last-txt{
    font-family: 'Nunito', sans-serif;
    color: var(--border-solid-hover);
}
.button-tri-txt{
    font-family: 'Nunito', sans-serif;
    color: var(--orange);
    font-weight: 700;
}
/* styles iconStar*/
.iconStar{
    color: var(--background-color-AiSatr);
   
    margin:4px;
}
.title-ingresso{
    font-family: 'Nunito', sans-serif;
    color:  var(--color-black);
    font-size: 1.6rem;
}
.container-main-igresso{
    width: 100%;    
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    margin-top: 1rem;
}
.container-main-igresso-column01{
    width: 400px;
    display: flex;
    flex-direction: row;
    /* margin-right: 70px; */

}
.container-main-igresso-column01 p{
    margin-left: 20px;
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    margin-top: 0.4rem;
}
.container-main-button-entrantes{
    width: 35px;
    border: 1px solid var(--border-solid);
    text-align: center;
    outline: none;
    /* background-color: orangered;
    color: #fff; */
}
.container-main-button-EDAD{
    width: 35px;
    border:  1px solid var(--border-solid);
    background-color: var(--border-solid);
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    font-family:  'Nunito', sans-serifs;
    font-weight: 600;
    border-radius: 2px;
}
.description-igresso{
    width: 400px;

}
.container-main-igresso-column02{
}
.container-main-igresso-column02 p{
    font-size: 14px;
    color: var(--color-description);
    font-family: 'Nunito', sans-serif;
  
    
}
.container-main-promotion{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
}
.button-cupon{
    background-color: var(--primary-color);
    border: 2px solid var(--color-text-bolinha);
    padding: 4px 20px;
}
.button-cupon a:hover{
    color:  var(--border-solid);
    font-weight: bold;
    font-size: 13px;
    color: (--color-text-bolinha);
    font-family: 'Nunito', sans-serif;
}
.button-link-cupon{
    color:  var(--border-solid);
    font-weight: bold;
    font-size: 11px;
    color: (--color-text-bolinha);
    font-family: 'Nunito', sans-serif;
}
.button-codigo{
    background-color: var(--primary-color);
    border: 2px solid var(--color-text-bolinha);
    padding: 4px 20px;
}
.button-codigo a:hover{
    color:  var(--border-solid);
    font-weight: bold;
    font-size: 13px;
    color: (--color-text-bolinha);
    font-family: 'Nunito', sans-serif;
}
.button-link-codigo{
    color:  var(--border-solid);
    font-weight: bold;
    font-size: 11px;
    color: (--color-text-bolinha);
    font-family: 'Nunito', sans-serif;
}
.footer-button{
    width: 300px;
    margin:  0px auto;
    text-align: right;
}
.button-first-text{
    font-family: 'Nunito', sans-serif;
    font-size: 15px;
    margin-bottom: 4px;
}
.button-last-text{
    font-family: 'Nunito', sans-serif;
    font-size: 15px;
    margin-bottom: 4px;
}
.button-tri-text{
    font-family: 'Nunito', sans-serif;
    font-size: 15px;
    margin-bottom: 4px;
}
.button-continue{
    width: 250px;
    height: 60px;
    background-color: var(--button-continuar);
    border: 1px solid var(--button-continuar);
    margin:  20px 0 0 0;
    cursor: pointer;
}
.button-continue span{
    color: var(--primary-color);
    font-size: 18px;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
}

.button-cancel{
    width: 250px;
    height: 60px;
    background-color: #CCCCCC;
    border: 1px solid #CCCCCC;
    margin: 0 10px 0 0;
    cursor: pointer;
    display: inline-block;
    padding: 15px 0;
    text-align: center;
}
.button-cancel span{
    color: #333333;
    font-size: 18px;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
}