/* styles containe-home */
.container-home{
    width: 100%;
    height: auto;
    min-height: 500px;
    background-color: var(--background-color);
}

/* styles container-main-pai*/
.container-main-pai{
    margin: 0 auto;
    width: 1200px;
    max-width: 95%;
}


.filtros{
    display: flex;
    justify-content: center;
}
.filtros .filtro-field{
    padding: 20px;
    display: flex;
    align-items: center;
}
.filtros .filtro-field p{
    margin-right: 10px;
}
.filtros .filtro-field input{
    width: 150px;
    padding: 5px 12px;
    border: 1px solid #cccccc;
    border-radius: 5px;
}
.filtros .filtro-field select{
    min-width: 150px;
    padding: 5px 12px;
    border: 1px solid #cccccc;
    border-radius: 5px;
}
.filtros .btn-generar{
    background-color: #006699;
    padding: 7px 15px;
    color: #fff;
    border: none;
    border-radius: 4px;
}

.filtros .btn-generar-pdf{
    background-color: #c43522;
    padding: 7px 15px;
    color: #fff;
    border: none;
    border-radius: 4px;
    margin-left: 7px;
}

.btn-generar-excel{
    background-color: #34eb67;
    padding: 7px 15px;
    color: #fff;
    border: none;
    border-radius: 4px;
    margin-left: 7px;
}

table, th, td {
    padding-right: 9px;
    padding-left: 5px;
    text-align: left;
}

.container-line-butacas{
    width: 100%;
    border:  6px solid var(--color-dark-grreen);
    border-radius: 10px;
    background-color: var(--color-dark-grreen);
}

.sala{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.bloco{
    padding: 20px;
    display: flex;
}

.linha{
    position: relative;
}

.assento{
    width: 20px;
    height: 20px;
    margin: 5px;
    cursor: pointer;
    border: none;
    background-color: #D3D4D6;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 10px;
    transition: 0.2s;
}

.assento.comprado {
    background-color: #00C455;
}

.assento.sinconfirmar {
    background-color: #F26522;
}

.subtitle-header {
    text-align: center;
    margin-bottom: 5px;
    font-family: 'Poppins';
    color: white;
    margin-top: 1rem;
}

.pantalla {
    margin-inline: auto;
    background: linear-gradient(rgb(255, 255, 255), rgb(76, 76, 76));
    height: 2rem;
    width: 50%;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

.infos-compra{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.title-selecione-header{
    margin-bottom: 5px;
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 22px;
    color: var(--color-description);
    margin-top: 2rem;
}

.flex-colunm {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.display-flex {
    display: flex;
    align-items: center;
    margin-right: 5px;
}