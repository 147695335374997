/* styles container-main-pai*/
.container-main-pai {
    margin: 0 auto;
    width: 1200px;
    max-width: 95%;
}

.filtros {
    display: flex;
    justify-content: center;
}

.filtros .filtro-field {
    padding: 20px;
    display: flex;
    align-items: center;
}

th,
td {
    border: 1px solid black;
}

.tdTablasCierre {
    text-align: center;
    padding: 3px 3px 3px 3px;
}

.inputCierre {
    width: 150px;
    padding: 5px 12px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    text-align: center;
}

.inputCierre::placeholder {
    font-size: 14px;
}