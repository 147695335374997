/* Formatação Goole Fontes */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600;700;800&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@200;300;400;600;700;800&display=swap');

/* Formatação padrao css */
* {
  margin: 0px;
  padding: 0px;
}

body {
  /* background-image: url('../src/assets/background.png'); */
  /* background-repeat: no-repeat; */
  width: 100%;
  height: 100vh; 
  box-sizing: border-box;
}

a,
button,
li,
h1,
h2,
h3,
h4,
h5,
h6,
div,
input,
select,
span,
b,
label,
p,
Button {
  list-style: none;
  text-decoration: none;
  font-family: 'Poppins';
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

:root {
  --color-dark-grreen: #00C455;
  --color-black: #000000;
  --color-text-bolinha: #E1E1E1;
  --color-description: #777777;
  --primary-color: #FFFFFF;
  --secudary-color: #f43f78;
  --button-color: #FF5722;
  --button-color-calendary: #69BF55;
  --button-continuar: #00C455;
  --orange: #F26522;
  --border-solid: #CECECE;
  --border-solid-hover: #848484;
  --background-color: #FFFFFF;
  --background-color-admin: #EDF3F4;
  --background-color-header: #f2f3f4;
  --background-color-AiSatr: #FF8F3B;
  --font-family: 'Poppins';
}