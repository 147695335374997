.cont-agr-hr{
    min-height: 50vh;
    max-height: 55vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 20px;
    width: 95%;
}
.container-area-modal-agregrar-hr{
    background-color: var(--color-dark-grreen);
    width: 400px;
	padding: 20px;
	position: fixed;
	top: 40px;
	left: 50%;
    transform: translateX(-50%);
    border-radius: 8px;

}

.container-area-modal-agregrar-hr-copy{
    background-color: var(--color-dark-grreen);
    width: 400px;
	padding: 20px;
	position: fixed;
	top: 40px;
	left: 50%;
    transform: translateX(-50%);
    border-radius: 8px;

}
.button-closed-agregrar-hr{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    color: var(--color-black);
    font-weight: bold;
    cursor: pointer;
}
.modal-container-agregrar-hr{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.title-modal-agregrar-hr{
    text-align: center;
    font-family: 'Nunito', sans-serif;
    font-size: 0.9rem;
    color: var(--primary-color);
    margin-bottom: 1rem;
}
.container-main-body-column{
    width: 100%;
    display: flex;

    align-items: center;
    justify-content: space-around;
    margin-bottom: 10px;
}
.container-main-input p{
    color: var(--primary-color);
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    font-size: 1.1rem;
    margin-bottom: 4px;
}
.container-main-input input{
    width: 90%;
    height: 30px;
    border: 1px solid var(--border-solid);
    border-radius: 4px;
    padding-left: 10px;
    outline: none;
}
.container-main-input select{
    width: 170px;
    height: 30px;
    border: 1px solid var(--border-solid);
    border-radius: 4px;
    padding-left: 10px;
    outline: none;
}
.footer-button-add-horario{
    width: 180px;
    height: 30px;
    border: 1px solid var(--border-solid);
    background-color: var(--primary-color);
    border-radius: 4px;
    outline: none;
    margin-top: 1rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 30px;
}
.footer-button-add-horario span{
    color:  var(--color-dark-grreen);
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    font-size: 1.1rem;
}
.hora-flex{
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}


.input-invisivel {
    width: 0 !important;
    height: 0 !important;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 !important;
    background: transparent !important;
    border: none !important;
}

.label-texto{
    display: inline-block;
    margin-top: 20px;
}

.teste{
    position: relative;
}

.container-main-body-column-n{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.container-main-input-n{
    width: 48%;
    position: relative;
}

.container-main-input-n input {
    width: 100%;
    height: 30px;
    border: 1px solid var(--border-solid);
    border-radius: 4px;
    padding-left: 10px;
    max-width: 94%;
    outline: none;
}

.container-main-input-n select {
    width: 100%;
    height: 30px;
    border: 1px solid var(--border-solid);
    border-radius: 4px;
    padding-left: 10px;
    outline: none;
}

.container-main-input-n p{
    color: var(--primary-color);
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    font-size: 1.1rem;
    margin-bottom: 4px;
}

.title-item{
    color: var(--primary-color);
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    display: inline-block;
    margin-bottom: 15px;
    font-size: 1.2rem;
    margin-bottom: 4px;
}

.button-remove{
    color: var(--primary-color);
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    display: inline-block;
    margin-left: 5px;
    font-size: 1.1rem;
    margin-bottom: 4px;
}

.item-text{
    color: var(--primary-color);
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    display: inline-block;
    margin-bottom: 15px;
    font-size: 1.1rem;
    margin-bottom: 4px;
}

.container-main-body{
    width: 100%;
}
