/* container button */
.container-button-logout{
    width: 100px;
    height: 31px;

    background-color: var(--orange);
    border:  1px solid var(--button-color);
    border-radius: 4px;
    
    color: var(--primary-color);
    cursor: pointer;
    margin-top: 10px
}