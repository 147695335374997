/* styles containe-home */
.container-home{
    width: 100%;
    height: 100vw;
    background-color: var(--background-color);
    
}
h1{
    font-size: 25px;
}
/* styles container-capa */
.container-capa{
    position: relative;
}
/* styles photo-capa */
.photo-capa{
    width: 100%;
}
/* styles container-user-data */

.container-header-ingessar-filme{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.container-search-filmes{
    margin-top: 30px;
    padding: 24px;
}
/* styles container-search-H1 */
.container-search-filmes h1{
    font-family: "Poppins";
    font-weight: 400;
    color: var(--button-color);
    margin-bottom: 0.4rem;

}
/* styles container-search-filme */
.search-filme{
    width: 100%;
    height: 3rem;

    border-radius: 6px;
    border: 2px solid  var(--border-solid);
    outline: none;

    padding-left: 10px;

}
.search-filme::placeholder{
    font-size: 1.3rem;
}
/* styles container-category */
.container-category{
    display: flex;
    align-items: center;
    padding: 26px;
    margin-top: -2rem
 ;
}
/* styles container-category-sub*/
.container-category-sub{
    border: 1px solid var(--border-solid);
    margin-right: 20px;
    padding: 4px;
    
}
/* styles container-subtitle */
.container-subtitle{
    font-family:  'Poppins';
    font-size: 1rem;
}
/* styles container-category-filme*/
.container-category-filme{

    flex-wrap: wrap;
}
/* styles firts-title-filme*/
.firts-title-filme{
    font-family: 'Nunito', sans-serif;
    color:  var(--orange);
    margin-left: 2rem;
    margin-bottom: 1.5rem;
    font-size: 2rem;
}
.container-listagem-filmes{
    flex-wrap: wrap;
    width: 100%;
    /* background-color: red; */
   
   

}
.button-icon-left,
.button-icon-right{
    position:absolute;
    width: 40px;
    height: 313px;
    background-color:  rgba(0, 0, 0, 0.6);
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    opacity: 0;
}


.button-icon-lef{
    left: 0;
}
.button-icon-right{
    right: 0;
}
.button-icon-right:hover{
  opacity: 1;
}
.button-icon-left:hover{
    opacity: 1;
}

/* styles main*/
.main{
    width: 230px;
    height: 313px;
   
    display: flex;
    align-items: center;
    border-radius: 20px;
    cursor: pointer;
    margin-left: 2rem;
    
}
/* styles container-listing-image*/
.container-listing-filmes{
    width: 230px;
    height: 313px;
   
    display: flex;
    margin-right: 20px;
}
/* styles container-titlr-filmes*/
.container-title-filmes{
    position: absolute;
    /* margin-left: 10px; */
  
    
}
/* styles container-title-filmes*/
.title-filmes{
    position: absolute;
    margin-top: 15rem;
    width: 16.3%;
    background-color: var(--orange);
    height: 74px;
    text-align: center  ;
    border-radius: 13px;
    font-size: 26px;
    color: var(--primary-color);
  
}
/* styles container-title-firstshors*/
.title-firtshors{
    position: absolute;
    margin-top: 17.5rem;
    width: 16.3%;;
    height: 74px;
    margin-left: 45px;
    border-radius: 13px;
    color: var(--primary-color);
  
}
/* styles container-listing-image*/
.title-filmesss{
    position: absolute;
    margin-top: 17.5rem;
    width: 16.3%;;
    height: 74px;
    margin-left: 130px;
    border-radius: 13px;
    color: var(--primary-color);
  
}
/* styles container-title-line*/
.title-line{
    position: absolute;
    margin-top: 17rem;
    width: 16.3%;;
    height: 74px;
    margin-left: 105px;
    border-radius: 13px;
  
    font-family: "Poppins";
    font-size: 36px;
    font-weight: 200;
    color: var(--primary-color);
  
}
/* styles container-image-filme*/
.conatiner-image-filme{
}
/* styles container-image-filmes*/
.image-filmes{
    width: 230px;
    height: 313px;
    border-radius: 20px;
}
/* styles container-last-title-filme*/
.last-title-filme{
    font-family: 'Nunito', sans-serif;
    color:  var(--orange);
    margin-left: 2rem;
    margin-bottom: 1.5rem;
    margin-top: 2rem;
    font-size: 2rem;
}
/* styles container-tri-title-filme*/
.tri-title-filme{
    font-family: 'Nunito', sans-serif;
    color:  var(--orange);
    margin-left: 2rem;
    margin-bottom: 1.5rem;
    margin-top: 2rem;
    font-size: 2rem;
}


.detalhes-filme-direita > .container-main-sesion{
    position: relative; 
    left: 50;
}

.table-caixa{
    margin-bottom: 20px;
}

.table-caixa tr td,.table-caixa tr th{
    border: 1px solid #CCCCCC;
    padding: 5px 10px;
}


/*
======================================================FORMATÇÃO DO ADMIN CSS===================================================
*/
.container-main-header-admin{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    padding: 24px;
}
.container-main-header-admin .container-button-ingressar{
    width: 94%;
    margin:  0px auto;
    text-align: right;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.container-button-ingressar .button-ingressar{
    background-color: var(--color-dark-grreen);
    border: 1px solid var(--color-dark-grreen);
    width: 176px;
    height: 40px;
    cursor: pointer;
}
.container-button-ingressar .button-ingressar span{
    font-size: 'Nunito', sans-serif;
    color: var(--primary-color);
    font-size: 0.9rem;
    font-weight: bold;
}
.container-main-box-sala{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center; 
}
.container-table-admin{
    width: 94%;
    margin-top: 2.3rem;
}
.tabela-cine-admin-main{
    width: 100%;
    border-collapse:collapse;
}
.tabela-cine-admin-main thead tr{
    background: #eee;
    border: 1px solid #ccc;
}
/* styles detalhes tabela-cine thead tr th*/
.tabela-cine-admin-main  thead tr th{
    padding: 10px;
    text-align: start;
    font-weight: 500;
    color:  var(--color-black)
}
/* styles detalhes tabela-cine tbody*/
.tabela-cine-admin-main  tbody tr{

}
/* styles detalhes tabela-cine tbody tr td*/
.tabela-cine-admin-main  tbody tr td{
    padding: 10px;
    text-align: start;
}


/* Carrosel */
.image-listagem-filmes{
    width: 100%;
    height: 100%;
    border-radius:  20px;
}

.container-main-image-listagem{
    display: flex;
    height: 313px;
    width: 250px;
    border-radius:  20px;
    font-size: 4em;
    position: relative;
}

.container-main-image-listagem .btn-deletar-filme{
    position: absolute;
    top: 0;
    right: 0rem;
    opacity: 0;
    transition: 0.25s;
    border: none;
    color: #ffffff;
    cursor: pointer;
    background-color: #f75d44;
    padding: 5px 10px;
    right: 10px ;
    top: 10px;
    border-radius: 5px;
}

.link-full{
    width: 100%;
}

.container-main-image-listagem:hover .btn-deletar-filme{
    opacity: 1;
}

.container-main-image-listagem .btn-deletar-filme:hover{
    background-color: #fd6c54;
}

.container-fotter-filmes{
    position: absolute;
    background-color: #f2cf22;
    width: 100%;
    height: auto;
    display: flex;
    bottom: 0;
    flex-direction: column;
    margin-top: 14.4rem;
    border-radius: 15px;
    padding: 10px 0;

}

.container-fotter-filmes.lancamento{
    background-color: #F26522;
}

.container-fotter-filmes .horarios{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
}


.container-fotter-filmes .horarios .pipe{
    width: 2px;
    height: 22px;
    margin-top: 2px;
    background-color: #fff;
}

.container-fotter-filmes .horarios p{
    font-size: 20px;
    font-weight: bold;
    margin: 0 10px 0 10px;
}

.footer-filmes{
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.container-title-filme{
    font-size: 1.2rem;
    font-weight: 400;
    font-family:  'Poppins';
    text-align: center;
    margin: 0;
}
.title-firts-filme{
    font-size: 1.5rem;
    margin-bottom: 2rem;
    font-family:  'Poppins';
}
.title-filme-filme{
    font-size: 1.5rem;
    margin-bottom: 2rem;
    font-family:  'Poppins';
}
.title-line-filme{
    font-size: 2rem;
    margin-bottom: 2rem;
    font-family:  'Poppins';
    height: 50px;
}

#carrosel {
    margin-left: 2%;
}

#carrosel h1{
    color: #F26522;
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 2rem;
}