/* styles containe-home */
.container-home{
    width: 100%;
    height: auto;
    min-height: 500px;
    background-color: var(--background-color);
}
/* styles container-capa */
.container-capa{
    position: relative;
}
/* styles photo-capa */
.photo-capa{
    width: 100%;
}
.container-search-filmes{
    margin-top: 30px;
    padding: 24px;
}
.input-pesquisar-rel{
    border: 1px solid #ccc;
    padding: 5px 10px;
    font-size: 15px;
    cursor: pointer;
    border-radius: 5px;
    color: #333;
}
/* styles container-search-H1 */
.container-search-filmes h1{
    font-family: "Poppins";
    font-weight: 400;
    color: var(--button-color);
    margin-bottom: 0.4rem;

}
/* styles container-search-filme */
.search-filme{
    width: 100%;
    height: 3rem;

    border-radius: 6px;
    border: 2px solid  var(--border-solid);
    outline: none;

    padding-left: 10px;
    font-size: 0.85rem;

}
/* styles container-main-pai*/
.container-main-pai{
    margin: 0 auto;
    width: 1200px;
    max-width: 95%;
}
/* styles title-informations*/
.title-informations{
    font-family: 'Nunito', sans-serif;
    font-size: 1.5rem;
    font-weight: 400;
    margin-top: 2rem;
    color: var(--color-description);
}
.container-area-informations{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
}
.container-header-infomations01{
    width: 550px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--border-solid);
    border-radius: 2px;
    padding: 10px;
    
}
.container-header-infomations01 h1{
    font-family: 'Nunito', sans-serif;
    font-size: 1.4rem;
    color: var(--color-description);
}
select{
    width: 100px;
    height: 30px;
    border: 1px solid var(--border-solid);
    border-radius: 6px;
}
.container-collum{
    background-color: #33acff;
    width: 100%;
}

.line-first{
    margin-top: 1rem;
    width: 60%;
    height:10px;
    border-radius: 3px;
    background-color: var(--color-dark-grreen);
    border:  1px solid var(--color-dark-grreen);;
}
.container-header-infomations02{
    width: 550px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    border: 1px solid var(--border-solid);
    border-radius: 2px;
    padding: 10px;
}
.container-header-infomations02 h1{
    font-family: 'Nunito', sans-serif;
    font-size: 1.4rem;
    color: var(--color-description);
}

.footer-inform{
    margin-top: 1rem;
    display: flex;
    justify-content: space-around;
}
.container-card{
    border: 1px solid var(--border-solid);
    width: 100px;
    height: 155px;
   
    
}
.header-footer-info1{
    background-color: #D6EAC5;
    width: 100px;
    height: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-bottom: 1px solid #cecece;
}
.header-footer-info1 h1{
    font-size: 0.8rem;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    color: var(--color-dark-grreen);
    width: 100%;

}

.header-footer-info2{
    background-color: #BBE8F2;
    width: 100px;
    height: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-bottom: 1px solid #cecece;
}
.header-footer-info2 h1{
    font-size: 0.8rem;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    text-align: center;
    color: #3686AF;
    width: 100%;

}
.header-footer-info3{
    background-color: #FBEED4;
    width: 90px;
    height: 20px;
    padding: 0px 0px 0px 10px;
    align-items: center;
    display: flex;
    border-bottom: 1px solid #cecece;
}
.header-footer-info3 h1{
    font-size: 0.9rem;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    color: #C1994D;
    text-align: center;

}
.header-footer-info4{
    background-color: #EFD3D7;
    width: 100px;
    height: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-bottom: 1px solid #cecece;
}
.header-footer-info4 h1{
    font-size: 0.8rem;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    text-align: center;
    color: #BB4945;
    width: 100%;

}

.header-footer-info5{
    background-color: #3E8ACC;
    width: 100px;
    height: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-bottom: 1px solid #cecece;
}
.header-footer-info5 h1{
    font-size: 0.8rem;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    text-align: center;
    color: #fff;
    width: 100%;
 

}
.description{
    margin-top: 3rem;
    text-align: center;
    font-size: 32px;
}
.container-relatorios{
    border: 1px solid var(--border-solid);
}
.container-collumn-icons{
    display: flex;
}

.container-icons-main{
    display: flex;
    flex-direction: column;
}
.container-info-relatorio{
    display: flex;
    width: 100%;
    padding: 24px;
    align-items: center;
}
.icon-andress{
    font-size: 30px;
    margin-right: 10px;
    color: green;
}
.icon-pencil{
    height: 25px;
    width: 25px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 10px;
    color:#fff;
    background-color: green;
    padding: 2px;
}
.icon-eayer{
    height: 25px;
    width: 25px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 10px;
    color:#fff;
    background-color: rgb(201, 198, 23);
    padding: 2px;
}
.container-description span{
    font-family: 'Nunito', sans-serif;
    font-size: 28px;
}
.container-description p{
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    color: var(--color-description)
}
.container-icons-main02{
    display: flex;
    flex-direction: column;
}
.container-info-relatorio02{
    display: flex;
    width: 100%;
    padding: 24px;
    align-items: center;
}
.icon-date02{
    height: 25px;
    width: 25px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 10px;
    color:#fff;
    background-color: #3933ff;
    padding: 2px;
}
.icon-circle02{
    height: 25px;
    width: 25px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 10px;
    color:#fff;
    background-color: #33acff;
    padding: 2px;
}
.icon-bandeira02{
    height: 25px;
    width: 25px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 10px;
    color:#fff;
    background-color: #c43522;
    padding: 2px;
}
.container-description02 span{
    font-family: 'Nunito', sans-serif;
    font-size: 28px;
}
.container-description02 p{
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    color: var(--color-description)
}

/* 
FORMATAÇÃO RELETORIOS VENDENDORES
*/
.container-vendedores-main{
    margin-top: 2rem;
    width: 100%;
    padding: 5px;
    border: 1px solid var(--border-solid);
}
.container-border-header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.header-vendedores{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
}
.header-vendedores h1{
    font-family: 'Nunito', sans-serif;
    font-size: 1.4rem;
    color: var(--color-description);
}
.button-cadastrar{
    width: 170px;
    height: 44px;

    background-color: var(--color-dark-grreen);
    border:  1px solid var(--button-color-calendary);
    border-radius: 4px;
    
    color: var(--primary-color);
    font-weight: bold;
    cursor: pointer;
    margin-top: -0.3rem;
    padding: 4px;;
}
.button-cadastrar-clientes span{
    color: var(--primary-color);
    font-family: 'Nunito', sans-serif;
    font-size: 1rem;
    font-weight: bold;

}
/* styles tabela-information*/
.tabela-information{
    width: 100%;
    border-collapse:collapse;
}
/* styles detalhes tabela-information thead tr*/
.tabela-information thead tr{
    background: #eee;
    border: 1px solid #ccc;
}
/* styles detalhes tabela-information thead tr th*/
.tabela-information thead tr th{
    padding: 10px;
    text-align: start;
    font-family: 'Nunito', sans-serif;
    font-size: 18px;
}
/* styles detalhes tabela-information tbody*/
.tabela-information tbody tr{
}
/* styles detalhes tabela-information tbody tr td*/
.tabela-information tbody tr td{
    padding: 10px;
    text-align: start;
    font-family: 'Nunito', sans-serif;
    font-size: 18px;

}
.container-ver{
    margin-top: 5px;
    width: 70px;
    height:  20px;

    background-color: #103FAC;
    border-radius: 6px;
    position: absolute;
    cursor: pointer;
    

}
.title-container-ver{
    color: #FFF;
    margin-left: 16px;
}

.relatorio-vendas-dia1{
    border: 1px solid #CECECE;
    margin: 20px auto;
    padding: 20px;
    width: 300px;
}

.relatorio-vendas-dia1 h2{
    font-size: 15px;
    margin: 0 0 5px 0;
}

.relatorio-vendas-dia1 h4{
    font-size: 15px;
    margin: 10px 0;
    text-align: center;
}

.box-infos-rel{
    text-align: center;
    padding: 20px 0;
    border-bottom: 1px dashed #999;
}

.box-infos-rel p{
    width: 100%;
    text-align: left;
}

.item-pelicula{
    margin: 20px 0;
}

.btn-gerar-pdf{
    background: #c43522;
    padding: 5px 7px;
    font-size: 12px;
    margin: 0 0 0 7px;
    color: #ffffff;
    border-radius: 5px;
}