/* styles containe-home */
.container-home {
    width: 100%;
    height: auto;
    min-height: 500px;
    background-color: var(--background-color);
}

/* styles container-capa */
.container-capa {
    position: relative;
}

/* styles photo-capa */
.photo-capa {
    width: 100%;
}

.container-search-filmes {
    margin-top: 30px;
    padding: 24px;
}

/* styles container-search-H1 */
.container-search-filmes h1 {
    font-family: "Poppins";
    font-weight: 400;
    color: var(--button-color);
    margin-bottom: 0.4rem;

}

/* styles container-search-filme */
.search-filme {
    width: 100%;
    height: 3rem;

    border-radius: 6px;
    border: 2px solid var(--border-solid);
    outline: none;

    padding-left: 10px;
    font-size: 0.85rem;

}

/* styles container-pai*/
.container-pai {
    margin: 0 auto;
    width: 1200px;
    max-width: 95%;
}

/* styles container-pai-filme*/
.container-pai-filme {
    margin: 50px auto;
    display: flex;

}

/*
======================================================FORMATÇÃO DO ADMIN CSS===================================================
*/
.container-main-header-admin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
}

.container-main-header-admin .container-button-ingressar {
    width: 94%;
    margin: 0px auto;
    text-align: right;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.container-button-ingressar .button-ingressar {
    background-color: var(--color-dark-grreen);
    border: 1px solid var(--color-dark-grreen);
    width: 176px;
    height: 40px;
    cursor: pointer;
}

.container-button-ingressar .button-ingressar span {
    font-size: 'Nunito', sans-serif;
    color: var(--primary-color);
    font-size: 0.9rem;
    font-weight: bold;
}

.container-main-box-sala {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.container-table-admin {
    width: 94%;
    margin-top: 2.3rem;
}

.tabela-cine-admin-main {
    width: 100%;
    border-collapse: collapse;
}

.tabela-cine-admin-main thead tr {
    background: #eee;
    border: 1px solid #ccc;
}

/* styles detalhes tabela-cine thead tr th*/
.tabela-cine-admin-main thead tr th {
    padding: 10px;
    text-align: start;
    font-weight: bold;
    color: var(--color-black);
    font-family: 'Nunito', sans-serif;
}

/* styles detalhes tabela-cine tbody*/
.tabela-cine-admin-main tbody tr {}

/* styles detalhes tabela-cine tbody tr td*/
.tabela-cine-admin-main tbody tr td {
    padding: 10px;
    text-align: start;
    font-family: 'Nunito', sans-serif;
}

/* BOX SALAS */
.box-sala-main {
    width: 180px;
    height: 120px;
    border: 1px solid var(--background-color-admin);
    background-color: var(--background-color-admin);
    padding: 24px;
    cursor: pointer;
    text-align: start;
    margin: 4px;
}

.first-text-box-sala-main {
    font-size: 24px;
}

.last-text-box-sala-main {
    font-family: "Poppins";
    color: var(--color-black);
    font-size: 1.4em;
    margin-top: 5px;
    margin-bottom: 0.5em;
}

.tri-text-box-sala-main {
    font-family: "Poppins";
    color: var(--color-description);
    font-size: 1em;
}

/* acitive */
.box-sala-main-active {
    width: 180px;
    height: 120px;
    border: 1px solid var(--orange);
    background-color: var(--orange);
    padding: 24px;
    cursor: pointer;
    text-align: start;
    margin: 4px;
}

.first-text-box-sala-main-active {
    font-size: 24px;
    color: var(--primary-color);
}

.last-text-box-sala-main-active {
    font-family: "Poppins";
    color: var(--primary-color);
    font-size: 1.4em;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 0.5em;

}

.tri-text-box-sala-main-active {
    font-family: "Poppins";
    color: var(--primary-color);
    font-size: 1em;
    font-weight: bold;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination button {
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    padding: 10px 20px;
    margin: 0 5px;
}

.pagination button:hover {
    background-color: #45a049;
}

.button-historial {
    margin: 0px auto;
    margin-bottom: 2rem;
    background-color: var(--color-dark-grreen);
    border: 1px solid var(--color-dark-grreen);
    width: 176px;
    height: 40px;
    cursor: pointer;
}

.button-historial span {
    font-size: 'Nunito', sans-serif;
    color: var(--primary-color);
    font-size: 0.9rem;
    font-weight: bold;
}