/* styles containe-home */
.container-home{
    width: 100%;
    height: auto;
    min-height: 500px;
    background-color: var(--background-color);
}
/* styles container-capa */
.container-capa{
    position: relative;
}
/* styles photo-capa */
.photo-capa{
    width: 100%;
}
.container-search-filmes{
    margin-top: 30px;
    padding: 24px;
}
/* styles container-search-H1 */
.container-search-filmes h1{
    font-family: "Poppins";
    font-weight: 400;
    color: var(--button-color);
    margin-bottom: 0.4rem;
}
/* styles container-search-filme */
.search-filme{
    width: 100%;
    height: 3rem;

    border-radius: 6px;
    border: 2px solid  var(--border-solid);
    outline: none;

    padding-left: 10px;
    font-size: 0.85rem;

}
/* styles container-pai*/
.container-pai{
    margin: 0 auto;
    width: 1200px;
    max-width: 95%;
}
/* styles container-pai-filme*/
.container-pai-filme{
    margin: 50px auto;
    display: flex;
}
.container-main-clientes{
    margin: 0 auto;
    width: 1200px;
    max-width: 95%;
    /* border: 1px solid red; */
}
.container-button-clientes{
    width:300px;
    margin:  0px 81%;
    text-align: right;
    margin-top: 2rem;
}
.button-cadastrar-clientes{
    width: 190px;
    height: 44px;
    margin-top: 2rem;
    background-color: var(--color-dark-grreen);
    border: 1px solid var(--color-dark-grreen);
    border-radius: 6px;
    padding: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.button-cadastrar span{
    color: var(--primary-color);
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: 600;

}
.title-serach{
    font-family: "Poppins";
    font-weight: 400;
    color: var(--color-description);
    margin-top: -30px;
    margin-bottom: 1rem;
    font-size: 1.6rem;
}
.input-search-client{
    width: 95%;
    height: 40px;
    border: 1px solid var(--border-solid);
    border-radius: 6px;
    padding-left: 20px;
    outline: none;

    margin-top: 10px;
}
.container-table-clientes{
    margin-top: 2rem;
}
/* styles tabela-cine-cliente*/
.tabela-cine-cliente{
    width: 97%;
    border-collapse:collapse;
}
/* styles detalhes tabela-cine-cliente thead tr*/
.tabela-cine-cliente thead tr{
    
    background: #eee;
    border: 1px solid #ccc;
}
.content-vipe{
    background-color: var(--color-dark-grreen);
    padding: 6px;
    color: var(--primary-color);
    font-family: 'Poppins', sans-serif;
   
}
.content-billetee{
    background-color: blue;
    padding: 4px;
    color: var(--primary-color);
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    font-size: 11px;
    margin-left:50%;

}
.affter-before{
    color: var(--color-description);
    font-weight: 300;
    font-family: 'Poppins', sans-serif;
}
/* styles detalhes tabela-cine-cliente thead tr th*/
.tabela-cine-cliente thead tr th{
    padding: 10px;
    text-align: start;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
}
/* styles detalhes tabela-cine-cliente tbody*/
.tabela-cine-cliente tbody tr{
}
/* styles detalhes tabela-cine-cliente tbody tr td*/
.tabela-cine-cliente tbody tr td{
    padding: 10px;
    text-align: start;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
}

.btn-deletar{
    background-color: #eb4034;   
    color: #ffffff;
    padding: 5px 12px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
}