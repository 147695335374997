/* styles containe-home */
.container-home{
    width: 100%;
    height: auto;
    min-height: 500px;
    background-color: var(--background-color);
}
/* styles container-capa */
.container-capa{
    position: relative;
}
/* styles photo-capa */
.photo-capa{
    width: 100%;
}
.container-search-filmes{
    margin-top: 30px;
    padding: 24px;
}
/* styles container-search-H1 */
.container-search-filmes h1{
    font-family: "Poppins";
    font-weight: 400;
    color: var(--button-color);
    margin-bottom: 0.4rem;

}
/* styles container-search-filme */
.search-filme{
    width: 100%;
    height: 3rem;

    border-radius: 6px;
    border: 2px solid  var(--border-solid);
    outline: none;

    padding-left: 10px;
    font-size: 0.85rem;

}
/* styles container-pai*/
.container-pai{
    margin: 0 auto;
    width: 1200px;
    max-width: 95%;
}
/* styles container-pai-filme*/
.container-pai-filme{
    margin: 50px auto;
    display: flex;
    
}
/* styles container-pai-filme .infomacoes-detalhes*/
.container-pai-filme .informacoes-detalhes{
    width: 100%;
    margin-left: 40px;
}
/* styles container-pai-filme .infomacoes-detalhes .detalhes-filme-direita*/
.container-pai-filme .informacoes-detalhes .detalhes-filme-direita{
    
}
.container-main-sesion{
    display: flex;
}
.container-sesion{
    display: flex;
    align-items: center;
}
.main-bolinha1{
    width: 60px;
    height: 60px;
    border: 1px solid var(--orange);
    background-color: var(--orange);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.main-bolinha1 span{
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    color: var(--primary-color)
}
.bolinha1{
    width: 60px;
    height: 60px;
    border: 1px solid var(--orange);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bolinha1 span{
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    color: var(--color-text-bolinha)
}
.container-line{
    width: 90px;
    border: 1px solid var(--orange);
}
.description-bolinha{
    width: 82%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 6px;
}
.description-bolinha p{
    font-family: 'Nunito', sans-serif;
    width: 140px;
    text-align: center;
    position: relative;
    left: -50px;
    color: var(--color-description);
}
/* styles container-pai-filme .infomacoes-detalhes .calendario-direita*/
.container-pai-filme .informacoes-detalhes .calendario-direita{
    margin: 40px 0 0 0;
}
/* styles detalhes-filme*/
.detalhes-filme{
    position: relative;
    width: 300px;
    max-height: 620px;
}
/* styles detalhes-filme img*/
.detalhes-filme img{
    width: 100%;
    max-height: 600px;
}
/* styles- detalhes .info-filme*/
.detalhes-filme .infos-filme-payment{
    padding: 20px;
    position: absolute;
    bottom: -10px;
    padding-top: 80px;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 10% , rgba(255,255,255,0.75) 15%, rgba(255,255,255,0.9) 20%,rgba(255,255,255,1) 30%, rgba(255,255,255,1) 100% );
}
/* styles detalhes-filme .info-filme-h2*/
.detalhes-filme .infos-filme-payment h2{
    margin: 0 0 15px 0;
}
/* styles detalhes-filme-info-filme-p*/
.detalhes-filme .infos-filme-payment p{
    margin: 6px 0 0 0;
    color: #707070;
}
/* styles detalhes-filme .info-filme h6*/
.detalhes-filme .infos-filme-payment h6{
    color: #AAA;
    margin: 20px 0 0 0;
}
.content-payment{
    width: 74%;
}
.title-payment{
    margin-bottom: 5px;
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 22px;
    color: var(--color-description);
    margin-top: 2rem;
    font-weight: bold;
}

.box-total-payment{
    position: absolute;
    right: 50px;
    top: 50px;
    background-color: var(--orange);
    text-align: center;
    padding: 15px 20px;
    border-radius: 10px;
}

.box-total-payment h4{
    margin: 0 !important;
    font-size: 25px;
    font-family: Poppins;
    color: #FFFFFF;
    font-weight: bold;
}
.box-total-payment p{
    margin: 0;
    font-size: 20px;
    color: #FFFFFF;
}

.subtitle-header{
    text-align: center;
    margin-bottom: 5px;
    font-family: 'Nunito', sans-serif;
    color: var(--color-description);
    margin-top: 1rem;
}
.container-line-butacas{
    width: 100%;
    border:  2px solid var(--color-dark-grreen);
    border-radius: 10px;
}
.description-first{
    margin-top: 1rem;
}
.subtitle-first-text{
    font-family: 'Nunito', sans-serif;
    color: var(--color-description);
}
.forma-pagamento{
    margin-top: 1rem;
}
.forma-pagamento h1{
    margin-bottom: 5px;
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 22px;
    color: var(--color-description);
    margin-top: 2rem;
}
.container-check-box{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-left: -10px;
}
.container-check-box span{
    font-family: 'Nunito', sans-serif;
    color: var(--color-description);
}
.container-check-box-collumn01{
    width:150px;
    height: 40px;
    display: flex;
    flex-direction: column;
}
.container-check-box-collumn02{
    width:150px;
    height: 40px;
    display: flex;
    flex-direction: column;
}
.footer-button-payment{
    text-align: right;
    margin-top: 2rem;
}

.input-forma-pagamento{
    width: 130px;
    height: 35px;
}

.input-valor-pagamento{
    margin: 0 10px;
    padding: 5px;
    height: 35px;
    border: 1px solid #c9c9c9;
    border-radius: 5px;
    width: 86px;
}

.btn-incluir-forma-pagamento{
    border: none;
    border-radius: 5px;
    background-color: #00C455;
    color: #fff;
    padding: 5px 10px;
}

.form-control p{
    margin: 10px 0;
    color: #666666;

    background-color: burlywood;
    width: 360px;
    color: #fff;
    padding: 10px 0;
    text-align: center;
    border-radius: 5px;
}

.tbl-formas-pagamento{
    border-collapse: collapse;
    margin: 15px 0 0 0;
    width: 360px;
}


.tbl-formas-pagamento tr th{
    padding: 5px 10px;
    border: 1px solid #ccc;
    text-align: center;
    font-size: 13px;
}

.tbl-formas-pagamento tr td{
    border: 1px solid #ccc;
    padding: 5px 10px;
    text-align: center;
    font-size: 13px
}

.btn-excluir-forma-pagamento{
    background-color: tomato;
    color: #fff;
    border: none;
    padding: 5px 12px;
    cursor: pointer;
    border-radius: 6px;
}

.infos-filme-carteira p,.infos-filme-carteira h6{
    opacity: 0;
}